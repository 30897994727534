@font-face {
  font-family: "CeraProBold";
  src: url("./fonts/CeraPRO-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "CeraProLight";
  src: url("./fonts/CeraPRO-Light.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "CeraProLight", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
